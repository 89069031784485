import React, { useContext } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";

import { ReceiptType } from "../receiptTypes";

import { useNavigateToReceipt } from "./navigateToReceipt";

import { ButtonContainer } from "@/components/ButtonContainer";
import Heading from "@/components/Heading";
import PageHeader from "@/components/PageHeader";
import { Sheet } from "@/components/Sheet";
import { useTranslate } from "@/i18n";
import { dateFormat } from "@/utils/date";
import AppContext from "@/contexts/appContext";
import { SubscriptionOutlet } from "@/components/SubscriptionRoute/subscriptionRoute";
import { log } from "@/logging/logger";
import { cancelCancellation } from "@/helpers/cancelCancellation";
import routes from "@/routes/routes";
import ScrollArticlesSmartEmbed from "@/components/ScrollArticlesSmartEmbed";
import { stripWww } from "@/utils/string";
import { useSubscriptionFetcher } from "@/fetch/subscription/useSubscriptionFetcher";
import { useCanCancelCancellation } from "@/fetch/subscription/changes";
import { BrickButton } from "@/components/BrickButton/brickButton";

type Props = {
  pendingCancellationDate: string;
};

const SubscriptionCancelledReceipt: React.FC<Props> = ({
  pendingCancellationDate,
}) => {
  const t = useTranslate(messages);
  const context = useContext(AppContext);
  const { domain, locale } = context.site;
  const { subscription } = useOutletContext<SubscriptionOutlet>();
  const navigate = useNavigate();
  const navigateToReceipt = useNavigateToReceipt();

  const { forceRefetch } = useSubscriptionFetcher(locale, domain);

  const { stopChange, error } = useCanCancelCancellation({
    subscriptionId: subscription.id,
  });

  if (error) {
    log.error(
      "useCanCancelCancellation failed. <SubscriptionCancelledReceipt/>",
      error,
    );
  }

  const cancelCallbacks = {
    onSuccess: () => {
      forceRefetch();
      navigateToReceipt({ type: ReceiptType.Resubscribe });
    },
    onError: () => {
      navigate(routes.error.path());
    },
  };

  return (
    <>
      <PageHeader>{t("title")}</PageHeader>
      <Sheet $primary>
        <Heading>{t("thanks")}</Heading>
        <p
          dangerouslySetInnerHTML={{
            __html: t("whileStillHasActiveSubscription", {
              newspaperLink: domain,
              newspaperShortLink: stripWww(domain),
              cancellationDate: dateFormat(pendingCancellationDate),
            }),
          }}
        />
        <p>{t("emailConfirmation")}</p>
        <ButtonContainer>
          <Link to={routes.myPage.path()}>
            <BrickButton as="span" label={t("myPage")} version="primary" />
          </Link>
          {stopChange && (
            <BrickButton
              as="button"
              version="outlined"
              width="normal"
              onClick={() =>
                cancelCancellation({
                  changeId: stopChange.id,
                  subscriptionId: subscription.id,
                  callbacks: cancelCallbacks,
                })
              }
              label={t("regretCancellation")}
            />
          )}
        </ButtonContainer>
      </Sheet>
      <ScrollArticlesSmartEmbed />
    </>
  );
};

export default SubscriptionCancelledReceipt;

const messages = {
  title: {
    nb: "Bekreftelse",
    nn: "Stadfesting",
  },
  myPage: {
    nb: "Min side",
    nn: "Mi side",
  },
  thanks: {
    nb: "Takk for nå, håper vi sees igjen!",
    nn: "Takk for no, håpar vi sjåast att!",
  },
  emailConfirmation: {
    nb: "Vi sender deg en bekreftelse av oppsigelsen på e-post.",
    nn: "Vi sender deg ei stadfesting av avslutta abonnement på e-post.",
  },
  regretCancellation: {
    nb: "Angre oppsigelsen",
    nn: "Angre oppseiinga",
  },
  whileStillHasActiveSubscription: {
    nb: "Ditt abonnement er avsluttet. Frem til <strong>{{cancellationDate}}</strong> kan du fortsatt lese alt vårt innhold på <a href='//{{newspaperLink}}'>{{newspaperShortLink}}</a> og se sport på <a href='//www.direktesport.no'>direktesport.no</a>. Innen denne datoen kan du også angre på oppsigelsen på Min side.",
    nn: "Ditt abonnementet er avslutta. Fram til <strong>{{cancellationDate}}</strong> kan du framleis lese alt innhaldet vårt på <a href='//{{newspaperLink}}'>{{newspaperShortLink}}</a> og sjå sport på <a href='//www.direktesport.no'>direktesport.no</a>. Innan denne datoen kan du angre på Mi side at du sa opp abonnementet.",
  },
};
