import dayjs from "dayjs";
import React from "react";
import { styled } from "styled-components";

import { BrickButton } from "@/components/BrickButton/brickButton";
import Address from "@/components/Address";
import PageHeader from "@/components/PageHeader";
import { Sheet } from "@/components/Sheet";
import ThemedText from "@/components/ThemedText";
import { TranslationFunction, useTranslate } from "@/i18n";
import { ChangeTypes, groupAddressChange } from "@/utils/address";
import colors from "@/theme/colors";
import { ButtonContainer } from "@/components/ButtonContainer";
import { AddressChange } from "@/fetch/address/getAddressChanges";

type Props = {
  addressChanges: AddressChange[];
  doDeleteChange: (changeId: string) => void;
};

const AddressChanges: React.FC<Props> = ({
  addressChanges,
  doDeleteChange,
}) => {
  const t = useTranslate(messages);
  const groupedAddressChanges = groupAddressChange(addressChanges, dayjs());

  const changeTypeText = {
    DELIVER_PERMANENT: t("permanentChange"),
    DELIVER_TEMPORARY: t("temporaryChange"),
    STOP: t("deliveryStop"),
  };

  return (
    <>
      <PageHeader>{t("title")}</PageHeader>
      {[ChangeTypes.active, ChangeTypes.future, ChangeTypes.past].map((key) => {
        const changes = groupedAddressChanges[key];
        return (
          <Sheet key={key}>
            <ThemedText $textColor="secondary" as="h3">
              {changeTitle(key, t)}
            </ThemedText>
            {changes.length > 0
              ? changes.map((change) => {
                  const { address } = change;
                  return (
                    <ChangeWrapper
                      key={`${change.id}-${change.startDate}-${change.endDate}`}
                    >
                      <Change>
                        <ChangeDate>
                          {change.startDate.format("DD.MM.YY")} -{" "}
                          {change.endDate && change.endDate.format("DD.MM.YY")}
                        </ChangeDate>
                        <ChangeAddress>
                          <>
                            <strong>{changeTypeText[change.type]}</strong>
                            {address ? <Address {...address} /> : null}
                          </>
                        </ChangeAddress>
                      </Change>
                      {change.isDeletable && (
                        <ButtonContainer>
                          <BrickButton
                            as="button"
                            version="outlined"
                            onClick={() => doDeleteChange(change.id)}
                            label={t("deleteChange")}
                            size="slim"
                          />
                        </ButtonContainer>
                      )}
                    </ChangeWrapper>
                  );
                })
              : noChangesMessage(key, t)}
          </Sheet>
        );
      })}
    </>
  );
};

export default AddressChanges;

const ChangeWrapper = styled.div`
  border: 2px solid ${colors.inactiveGray};
  padding: 10px;

  & + & {
    margin-top: 20px;
  }
`;

const Change = styled.div`
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  justify-content: space-around;
`;

const ChangeDate = styled.div`
  margin-bottom: 10px;
  color: ${colors.grayText};
`;

const ChangeAddress = styled.div`
  flex-grow: 1;
`;

const messages = {
  title: {
    nb: "Leveringsendringer",
    nn: "Leveringsendringar",
  },
  pastChange: {
    nb: "Historikk",
    nn: "Historikk",
  },
  activeChange: {
    nb: "Skjer nå",
    nn: "Skjer no",
  },
  futureChange: {
    nb: "Planlagte endringer",
    nn: "Planlagde endringar",
  },
  noChangesPast: {
    nb: "Ingen historiske endringer",
    nn: "Ingen historiske endringar",
  },
  noChangesActive: {
    nb: "Ingen pågående endringer",
    nn: "Ingen pågåande endringar",
  },
  noChangesFuture: {
    nb: "Ingen planlagte endringer",
    nn: "Ingen planlagde endringar",
  },
  deliveryStop: {
    nb: "Midlertidig stans av levering",
    nn: "Mellombels stans av levering",
  },
  permanentChange: {
    nb: "Ny fast leveringsadresse",
    nn: "Ny fast leveringsadresse",
  },
  temporaryChange: {
    nb: "Midlertidig endring av leveringsadresse",
    nn: "Mellombels endring av leveringsadresse",
  },
  deleteChange: {
    nb: "Slett",
    nn: "Slett",
  },
};

const changeTitle = (
  key: ChangeTypes,
  t: TranslationFunction<typeof messages>,
) => {
  switch (key) {
    case ChangeTypes.past:
      return t("pastChange");
    case ChangeTypes.active:
      return t("activeChange");
    case ChangeTypes.future:
      return t("futureChange");
    default:
      throw new Error(`Unknown change type (${key})`);
  }
};

const noChangesMessage = (
  key: ChangeTypes,
  t: TranslationFunction<typeof messages>,
) => {
  switch (key) {
    case ChangeTypes.past:
      return t("noChangesPast");
    case ChangeTypes.active:
      return t("noChangesActive");
    case ChangeTypes.future:
      return t("noChangesFuture");
    default:
      throw new Error(`Unknown change type (${key})`);
  }
};
