import React from "react";
import { useNavigate } from "react-router-dom";

import { Sheet } from "@/components/Sheet";
import { useTranslate } from "@/i18n";
import { dateFormat } from "@/utils/date";
import { ButtonContainer } from "@/components/ButtonContainer";
import { Subscription } from "@/fetch/mappers/subscriptionMapper";
import { log } from "@/logging/logger";
import { cancelCancellation } from "@/helpers/cancelCancellation";
import routes from "@/routes/routes";
import { useNavigateToReceipt } from "@/pages/ReceiptPage/components/navigateToReceipt";
import { ReceiptType } from "@/pages/ReceiptPage/receiptTypes";
import { useCanCancelCancellation } from "@/fetch/subscription/changes";
import { BrickButton } from "@/components/BrickButton/brickButton";

type Props = { subscription: Subscription };

const SubscriptionCancelled: React.FC<Props> = ({ subscription }) => {
  const t = useTranslate(messages);
  const navigate = useNavigate();
  const navigateToReceiptPage = useNavigateToReceipt();

  const { stopChange, error } = useCanCancelCancellation({
    subscriptionId: subscription.id,
  });

  if (error) {
    log.error(
      "useCanCancelCancellation failed. <SubscriptionCancelled/>",
      error,
    );
  }

  const cancelCallbacks = {
    onSuccess: () => {
      navigateToReceiptPage({ type: ReceiptType.Resubscribe });
    },
    onError: () => {
      navigate(routes.error.path());
    },
  };

  return stopChange && subscription.pendingCancellationDate ? (
    <Sheet $primary>
      <h2>{t("cancelled")}</h2>
      <p>
        {t("subscriptionInfo", {
          name: subscription.catalanaTexts.title ?? subscription.name,
          pendingCancellationDate: dateFormat(
            subscription.pendingCancellationDate,
          ),
        })}
      </p>
      <p>{t("campaignRider")}</p>
      <ButtonContainer>
        <BrickButton
          as="button"
          version="primary"
          width="normal"
          onClick={() =>
            cancelCancellation({
              changeId: stopChange.id,
              subscriptionId: subscription.id,
              callbacks: cancelCallbacks,
            })
          }
          label={t("cancelCancellation")}
        />
      </ButtonContainer>
    </Sheet>
  ) : null;
};

export default SubscriptionCancelled;

const messages = {
  cancelled: {
    nb: "Ditt abonnement er sagt opp",
    nn: "Ditt abonnement er sagt opp",
  },
  subscriptionInfo: {
    nb: "Abonnementet «{{name}}» er sagt opp og avsluttes den {{pendingCancellationDate}}.",
    nn: "Abonnementet «{{name}}» er sagt opp og vert avslutta den {{pendingCancellationDate}}.",
  },
  campaignRider: {
    nb: "Husk at du ikke vil kunne kjøpe et nytt abonnement til introduksjonspris på denne avisen, eller på noen av de andre avisene i Amedia (inkludert våre samarbeidsaviser).",
    nn: "Husk at du ikkje vil kunne kjøpe eit nytt abonnement til introduksjonspris på denne avisa, eller på nokon av dei andre avisene i Amedia (inkludert våre samarbeidsaviser).",
  },
  cancelCancellation: {
    nb: "Angre oppsigelsen",
    nn: "Angre oppsigelsen",
  },
};
