import { useContext } from "react";
import { styled } from "styled-components";
import { css } from "@amedia/brick-tokens";

import { RelevantTitles } from "../RelevantTitles/relevantTitles";
import { BrickButton } from "../BrickButton/brickButton";

import { useMediaQuery } from "@/hooks/useMediaQuery";
import { ProductFeature } from "@/fetch/mappers/subscriptionMapper";
import { scrollToId } from "@/utils/scrollToId";
import AppContext from "@/contexts/appContext";
import { includesPlussalt } from "@/utils/productFeatures";

const Card = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: var(--brick-radii-baseL);
  background: var(--brick-colors-baseNeutral00);
  padding: 32px;

  @media (max-width: 1020px) {
    margin: 16px;
  }
`;

const CardRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-right: 16px;

  @media (max-width: 680px) {
    flex-direction: column-reverse;
    margin-bottom: 16px;
    align-items: center;
  }
`;

const CardBody = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 680px) {
    align-items: center;
  }
`;

const Illustration = styled.img`
  height: 100%;
  max-height: 160px;
  margin: 0px;

  @media (max-width: 680px) {
    margin-bottom: 32px;
  }
`;

const Divider = styled.div`
  border: 1px solid var(--brick-colors-baseNeutral200);
  margin-top: 8px;
  margin-bottom: 8px;
`;

const FeatureButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 16px;
  padding-bottom: 16px;
  padding-top: 16px;
  max-width: 360px;
`;

export type FeatureButton = { id: string; label: string; bgColor?: string };

interface CurrentSubscriptionCardProps {
  title: string;
  description: string;
  imageUrl: string;
  productFeatures: ProductFeature[];
  productFeatureButtons: FeatureButton[];
}

export const CurrentSubscriptionCard = (
  props: CurrentSubscriptionCardProps,
) => {
  const isMobileDevice = useMediaQuery("(max-width: 680px)");
  const context = useContext(AppContext);
  const relevantTitles = context.relevantTitles;

  const style = {
    title: css({
      color: "$baseFg",
      fontFamily: "$baseTitlepieceM",
      fontSize: "$baseTitlepieceM",
      fontWeight: "$baseTitlepieceM",
      lineHeight: "$baseTitlepieceM",
      letterSpacing: "$baseTitlepieceM",
      margin: 0,
      paddingBottom: 16,
      textAlign: "left",
    }),
    subtitle: css({
      color: "$baseFg",
      fontFamily: "$baseSubheadM",
      fontSize: "$baseSubheadM",
      fontWeight: "$baseSubheadM",
      lineHeight: "$baseSubheadM",
      letterSpacing: "$baseSubheadM",
      marginTop: 16,
      maxWidth: 480,
    }),
    caption: css({
      color: "$baseNeutral600",
      fontFamily: "$baseCaptionM",
      fontSize: "$baseCaptionM",
      fontWeight: "$baseCaptionM",
      lineHeight: "$baseCaptionM",
      letterSpacing: "$baseCaptionM",
      marginTop: 16,
      textAlign: "left",
    }),
    description: css({
      color: "$baseFg",
      fontFamily: "$baseIngressL",
      fontSize: "$baseIngressL",
      fontWeight: "$baseIngressL",
      lineHeight: "$baseIngressL",
      letterSpacing: "$baseIngressL",
      marginTop: 16,
      maxWidth: 480,
    }),
    descriptionSmall: css({
      color: "$baseFg",
      fontFamily: "$baseIngressM",
      fontSize: "$baseIngressM",
      fontWeight: "$baseIngressM",
      lineHeight: "$baseIngressM",
      letterSpacing: "$baseIngressM",
      marginTop: 16,
      maxWidth: 480,
    }),
  };

  const descriptionStyle = isMobileDevice
    ? style.descriptionSmall()
    : style.description();

  const buttons: FeatureButton[] = props.productFeatureButtons.map((value) => ({
    id: value.id,
    label: value.label,
    bgColor: value.bgColor,
  }));

  const hasPlussAlt = includesPlussalt(props.productFeatures);

  const productFeatureCodes = props.productFeatures.map((pf) => pf.code);
  const featureButtons: FeatureButton[] = buttons
    .filter((f) => productFeatureCodes.includes(f.id))
    .concat({ id: "minside", label: "Min side", bgColor: "#eae5d1" });

  const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
  `;

  return (
    <>
      <Card>
        <CardRow>
          <CardBody>
            <h1 className={style.title()}>{props.title}</h1>
            <p className={descriptionStyle}>{props.description}</p>
          </CardBody>
          <Illustration src={props.imageUrl} alt="" />
        </CardRow>
        {!isMobileDevice && hasPlussAlt && (
          <>
            <Divider />
            <RelevantTitles relevantTitles={relevantTitles} />
          </>
        )}
      </Card>
      {isMobileDevice && (
        <FeatureButtonContainer>
          <p className={style.subtitle()}>Dette er inkludert</p>
          <ButtonWrapper>
            {featureButtons.map((pr) => (
              <BrickButton
                as="button"
                label={pr.label}
                onClick={(_e) => scrollToId(pr.id)}
                version="secondary"
                key={pr.id}
                style={{ margin: 4 }}
                size="small"
                customBgColor={pr.bgColor}
              />
            ))}
          </ButtonWrapper>
        </FeatureButtonContainer>
      )}
    </>
  );
};
export default CurrentSubscriptionCard;
