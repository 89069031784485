import React from "react";

import PageHeader from "@/components/PageHeader";
import { Sheet } from "@/components/Sheet";
import { useTranslate } from "@/i18n";
import { BrickButton } from "@/components/BrickButton/brickButton";

const ResubscribeReceipt: React.FC = () => {
  const t = useTranslate(messages);

  return (
    <>
      <PageHeader>{t("title")}</PageHeader>
      <Sheet>
        <p>{t("receipt")}</p>
        <BrickButton
          as="a"
          label={t("toFrontpage")}
          version="primary"
          width="normal"
          href="/"
        />
      </Sheet>
    </>
  );
};

export default ResubscribeReceipt;

const messages = {
  receipt: {
    nb: "Abonnementet ditt er aktivt igjen. Takk for at du vil fortsette å lese avisen.",
    nn: "Abonnementet ditt er aktivt igjen. Takk for at du vil fortsette å lese avisa.",
  },
  toFrontpage: {
    nb: "Til forsiden",
    nn: "Til framsida",
  },
  title: {
    nb: "Takk!",
    nn: "Takk!",
  },
};
