export const infoText = {
  title: {
    nb: "Slik får du mest ut av abonnementet ditt",
    nn: "Slik får du mest ut av abonnementet ditt",
  },
  subTitle: {
    nb: "Dette får du tilgang til med {{subscription}} ",
    nn: "Dette får du tilgang til med {{subscription}} ",
  },
  newSubscriptionTitle: {
    nb: "Velkommen som abonnent!",
    nn: "Velkommen som abonnent!",
  },
  newSubDescription: {
    nb: "Med {{ subscriptionName }} får du nyheter fra {{ publicationDomain }} og resten av landet i ett abonnement.",
    nn: "Med {{ subscriptionName }} får du nyheiter frå {{ publicationDomain }} og resten av landet i eitt abonnement.",
  },
  downloadDescriptionEavis: {
    nb: "I appen kan du velge hvilke temaer og områder du vil varsles om, og du har alltid siste nytt tilgjengelig, uansett hvor du befinner deg. I appen finner du også eAvisen om du ønsker å lese papiravisen i digitalt format.  ",
    nn: "I appen kan du velje kva for temaer og områder du vil varslast om, og du har alltid siste nytt tilgjengeleg, uansett kor du befinn deg. I appen finn du også eAvisa om du ønskjer å lese papiravisa i digitalt format.   ",
  },
  downloadDescription: {
    nb: "I appen kan du velge hvilke temaer og områder du vil varsles om, og du har alltid siste nytt tilgjengelig, uansett hvor du befinner deg. ",
    nn: "I appen kan du velje kva for temaer og områder u vil varslast om, og du har alltid siste nytt tilgjengelig, uansett kor du befinn deg. ",
  },
  downloadSubDescription: {
    nb: "Du logger deg på appen med din aID-bruker. ",
    nn: "Du loggar deg på appen med din aID.  ",
  },
  subTitle2: {
    nb: "Husk å dele abonnementet med de du bor med",
    nn: "Hugs å dele abonnementet med dei du bur med",
  },
  eAvis: {
    nb: "eAvisen er en digital utgave av papiravisen. Du kan lese den både i avisappen og i nettleseren ved å gå til {{domain}}/eavis. ",
    nn: "eAvisa er ein digital utgåve av papiravisa. Du kan lese den både i avisappen og i nettlesaren ved å gå til {{domain}}/eavis. ",
  },
  shareSubscription: {
    nb: "Med aID Familie kan du dele abonnementet ditt med inntil fire personer du bor sammen med. Borteboende studenter regnes som noen du bor sammen med.",
    nn: "Med aID Familie kan du dele abonnementet ditt med inntil fire personer du bur med. Bortebuande studentar regnast som ein del av same husstand.",
  },
  sharePlussAltSubscription: {
    nb: "Med aID Familie kan du dele abonnementet ditt og alt det inkluderer med de du bor med, både barn og voksne. De får, som deg, tilgang til {{publicationDomain}}, over 100 andre aviser, ALT-appen, alle sendinger på Direktesport og ubegrenset med lytting i podkastappen Untold. Borteboende studenter regnes som noen du bor sammen med.",
    nn: "Med aID Familie kan du dele abonnementet ditt og alt det inkluderer med dei du bur med, både barn og vaksne. Dei får, som deg, tilgang til {{publicationDomain}}, over 100 andre aviser, ALT-appen, alle sendingar på Direktesport og ubegrensa med lytting i podkastappen Untold. Bortebuande studentar reknast som nokon du bur med.",
  },

  eAviskioskenTitle: {
    nb: "eAviskiosken",
    nn: "eAviskiosken",
  },
  eAviskiosken: {
    nb: "Du kan lese alle papirutgivelsene i +Alt kvelden før utgivelse gjennom eAviskiosken på nett ved å gå til {{domain}}/vis/aviskiosk.  ",
    nn: "Du kan lese alle papirutgåvene i +Alt kvelden før utgjeving gjennom eAviskiosken på nett ved å gå til {{domain}}/vis/aviskiosk. ",
  },
  papiravisenTitle: {
    nb: "Avisen på papir",
    nn: "Avisen på papir",
  },
  papiravisen: {
    nb: "Avisen på papir",
    nn: "Avisen på papir",
  },
  request: {
    nb: "For å få tilgang til eAvisen må du oppgradere til Digital med eAvis. ",
    nn: "For å få tilgang til eAvisen må du oppgradere til Digital med eAvis. ",
  },
  minSideTitle: {
    nb: "Hjelp deg selv på Min side",
    nn: "Hjelp deg sjølv på Mi side",
  },
  minSide: {
    nb: "På Min side får du full oversikt. Her kan du administrere og gjøre endringer i ditt abonnement.",
    nn: "På Mi side får du full oversikt. Her kan du administrere og gjere endringar i abonnementet ditt.",
  },
  helperText: {
    nb: "Last ned appen for iOS eller Android",
    nn: "Last ned appen for iOS eller Android",
  },
  ios: {
    nb: "iOS",
    nn: "iOS",
  },
  android: {
    nb: "Android",
    nn: "Android",
  },
  downloadIos: {
    nb: "Last ned for iOS",
    nn: "Last ned for iOS",
  },
  downloadAndroid: {
    nb: "Last ned for Android",
    nn: "Last ned for Android",
  },
  dittAbonnement: {
    nb: "Ditt abonnement",
    nn: "Ditt abonnement",
  },
  minSideBtn: {
    nb: "Gå til Min side",
    nn: "Gå til Mi side",
  },
};
