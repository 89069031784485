import { ReactNode, useState } from "react";
import { styled } from "styled-components";

import { illustrationSource } from "./utils";
import {
  productFeatureButtons,
  productFeatureColor,
} from "./productFeatureButtons";

import { BrickButton } from "@/components/BrickButton/brickButton";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import BrickIcon from "@/public/icons/BrickIcon";
import { useTranslate } from "@/i18n";

const Card = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: var(--brick-radii-baseL);
  border-top: 14px solid transparent;
  border-color: #ffe5da;
  background: var(--brick-colors-baseNeutral00);
  padding: 32px;
  margin-bottom: 32px;

  @media (max-width: 1020px) {
    margin: 16px;
  }
`;

const CardBody = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media (max-width: 680px) {
    flex-direction: column;
    margin-bottom: 0;
  }
`;

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 645px;
`;

const Illustration = styled.img`
  height: auto;
  width: 120px;
`;

const BottomContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 16px;

  @media (max-width: 680px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const ExpandedButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin: 0;
  cursor: pointer;
  background: transparent;
  border: 1px solid transparent;
  padding: 8px;
  margin-bottom: 8px;

  @media (max-width: 680px) {
    width: 100%;
    justify-content: center;
    background: #eeeeee;
    padding: 4px;
    border: 1px solid #cfcfcf;
    border-radius: 16px;
  }
`;

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  gap: 16px;

  @media (max-width: 680px) {
    margin-top: 16px;
    align-items: flex-start;
    width: 100%;
  }
`;

const Title = styled.h4`
  font-family: var(--brick-fonts-baseSubheadM);
  font-size: var(--brick-fontSizes-baseSubheadM);
  font-weight: 500;
  line-height: var(--brick-lineHeights-baseSubheadM);
  letter-spacing: var(--brick-letterSpacings-baseSubheadM);
  color: var(--brick-colors-baseFg);
  margin: 0;
`;

const Description = styled.p`
  font-family: var(--brick-fonts-baseBodyS);
  font-size: var(--brick-fontSizes-baseBodyS);
  font-weight: var(--brick-fontWeight-baseBodyS);
  line-height: var(--brick-lineHeights-baseBodyS);
  letter-spacing: var(--brick-letterSpacings-baseBodyS);
  color: var(--brick-colors-baseNeutral800);
  margin: 8px 0px 8px 0px;
`;

const HelperText = styled.span`
  font-family: var(--brick-fonts-baseCaptionS);
  font-size: var(--brick-fontSizes-baseCaptionS);
  font-weight: var(--brick-fontWeight-baseCaptionS);
  line-height: var(--brick-lineHeights-baseCaptionS);
  letter-spacing: var(--brick-letterSpacings-baseCaptionS);
  color: var(--brick-colors-baseSubtleFg);
`;

const Span = styled.span`
  font-family: var(--brick-fonts-baseUtilityM);
  font-size: var(--brick-fontSizes-baseUtilityM);
  font-weight: var(--brick-fontWeights-baseUtilityM);
  line-height: var(--brick-lineHeights-baseUtilityM);
  letter-spacing: var(--brick-letterSpacings-baseUtilityM);
  color: var(--brick-colors-baseNeutral800);
  margin-right: 18px;
`;

const Chevron = styled.div<{ expanded: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
  background: #eeeeee;
  border-radius: 100%;
  ${(props) => (props.expanded ? "transform: rotate(180deg);" : "")}
`;

type Button = {
  id: string;
  label: string;
  href: string;
};

export type SubscriptionContentType =
  | "direktesport"
  | "podcast"
  | "alt"
  | "sharing"
  | "eAvis"
  | "eAviskiosken"
  | "papiravis"
  | "minSide"
  | "hundrePlussAviser"
  | "lastNed";

interface SubscriptionInfoCardProps {
  title: string;
  id?: string;
  subTitle?: string;
  description: Array<string>;
  helperText?: string;
  buttons?: Button[];
  expandedContent?: ReactNode;
  type: SubscriptionContentType;
}

export const SubscriptionInfoCard = (props: SubscriptionInfoCardProps) => {
  const [expand, setExpand] = useState<boolean>(false);
  const isMobileDevice = useMediaQuery("(max-width: 680px)");
  const t = useTranslate(text);

  const productFeature = productFeatureButtons.find((f) => f.id === props.id);
  const minSideFeature = {
    label: "Min side",
    bgColor: productFeatureColor.default,
  };

  const borderColor = {
    podcast: productFeatureColor.podcast,
    direktesport: productFeatureColor.direktesport,
    alt: productFeatureColor.altno,
    hundrePlussAviser: productFeatureColor.plussalt,
    sharing: productFeatureColor.default,
    eAvis: productFeatureColor.default,
    eAviskiosken: productFeatureColor.default,
    papiravis: productFeatureColor.default,
    minSide: productFeatureColor.default,
    lastNed: productFeatureColor.default,
  };

  const illustrationSrc = illustrationSource(props.type);

  const MLabel = ({
    bgColor = "white",
    label,
  }: {
    bgColor?: string;
    label: string;
  }) => (
    <MobileLabel bgColor={bgColor}>
      <p> {label} </p>
    </MobileLabel>
  );

  const MobileLabel = styled.div<{ bgColor: string }>`
    background: ${(props) => props.bgColor};
    margin-bottom: 16px;
    border-radius: 8px;
    > p {
      margin: 0 4px;
      padding: 4px;
      font-size: 14px;
    }
  `;

  const cardLabel = {
    label: productFeature ? productFeature.label : minSideFeature.label,
    bgColor: productFeature ? productFeature.bgColor : minSideFeature.bgColor,
  };

  return (
    <Card style={{ borderColor: borderColor[props.type] }} id={props.id}>
      <CardBody>
        <TopContainer>
          {isMobileDevice && (
            <MLabel label={cardLabel.label} bgColor={cardLabel.bgColor} />
          )}
          <Title>{props.title}</Title>
          {props.subTitle && <h4>{props.subTitle}</h4>}
          {props.description.map((text, index) => (
            <Description key={`${props.type}-desc-${index}`}>
              {" "}
              {text}{" "}
            </Description>
          ))}
        </TopContainer>
        {!isMobileDevice && <Illustration src={illustrationSrc} alt="" />}
      </CardBody>
      {/* TODO: region should gain focus when opened */}
      <div role="region" aria-labelledby="buttonId" aria-live="polite">
        {expand && props.expandedContent}
      </div>
      <BottomContainer>
        {isMobileDevice ? (
          <>
            {props.expandedContent && (
              <ExpandedButton
                aria-expanded={expand}
                onClick={() => setExpand(!expand)}
              >
                <Span> {expand ? "Vis mindre" : "Vis mer"} </Span>
                <Chevron expanded={expand}>
                  <BrickIcon iconId="chevron-down" width="16px" height="16px" />
                </Chevron>
              </ExpandedButton>
            )}
            {props.helperText && <HelperText>{props.helperText}</HelperText>}
            <ButtonRow>
              {props.buttons &&
                props.buttons.map((btn) => (
                  <BrickButton
                    key={`button-${btn.id}`}
                    id={btn.id}
                    as="a"
                    version="primary"
                    label={btn.label}
                    href={btn.href}
                  />
                ))}
            </ButtonRow>
          </>
        ) : (
          <>
            <ButtonRow>
              {props.buttons &&
                props.buttons.map((btn) => (
                  <BrickButton
                    key={`button-${btn.id}`}
                    id={btn.id}
                    as="a"
                    version="primary"
                    label={btn.label}
                    href={btn.href}
                  />
                ))}
            </ButtonRow>
            {props.expandedContent && (
              <ExpandedButton onClick={() => setExpand(!expand)}>
                <Span aria-expanded={expand}>
                  {" "}
                  {expand ? t("visMindre") : t("visMer")}
                </Span>
                <Chevron expanded={expand}>
                  <BrickIcon iconId="chevron-down" width="16px" height="16px" />
                </Chevron>
              </ExpandedButton>
            )}
          </>
        )}
      </BottomContainer>
    </Card>
  );
};

const text = {
  visMer: {
    nb: "Vis mer",
    nn: "Vis meir",
  },
  visMindre: {
    nb: "Vis mindre",
    nn: "Vis mindre",
  },
};
