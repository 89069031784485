import React from "react";
import { Link } from "react-router-dom";

import { InfoBox } from "../InfoBox";
import { Sheet } from "../Sheet";
import { BrickButton } from "../BrickButton/brickButton";

import { useTranslate } from "@/i18n";
import routes from "@/routes/routes";
import { Subscription } from "@/fetch/mappers/subscriptionMapper";

type Props = {
  subscription: Subscription;
};

const SubscriptionTypeNotSupported: React.FC<Props> = ({ subscription }) => {
  const t = useTranslate(messages);

  return (
    <>
      <Sheet>
        <InfoBox title={t("title")}>
          <p>
            {t("message", {
              name: subscription.catalanaTexts.title ?? subscription.name,
            })}
          </p>
          <p>
            {t("myPage")}
            <Link to={routes.myPage.path()}>{t("myPageLink")}</Link>.
          </p>
          <BrickButton
            as="button"
            label={t("goBack")}
            version="primary"
            width="normal"
            onClick={() => window.history.back()}
          />
        </InfoBox>
      </Sheet>
    </>
  );
};

const messages = {
  title: {
    nb: "Beklager",
    nn: "Beklagar",
  },
  message: {
    nb: "Siden du prøvde å nå eksisterer ikke for ditt produkt; {{name}}.",
    nn: "Sida du prøvde å nå eksisterer ikkje for ditt produkt; {{name}}.",
  },
  myPage: {
    nb: "Du kan se og administrere ditt abonnement på ",
    nn: "Du kan sjå og administrere ditt abonnement på ",
  },
  myPageLink: {
    nb: "Min side",
    nn: "Mi side",
  },
  goBack: {
    nb: "Gå tilbake",
    nn: "Gå tilbake",
  },
};

export default SubscriptionTypeNotSupported;
