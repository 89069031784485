import dayjs from "dayjs";
import React, { ComponentType, useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "styled-components";

import DatePicker from "@/components/DatePicker";
import { FailureBox } from "@/components/InfoBox";
import InputError from "@/components/InputError";
import { InputField } from "@/components/InputField";
import { Option, Select } from "@/components/Select";
import { Sheet } from "@/components/Sheet";
import { useTranslate } from "@/i18n";
import { log } from "@/logging/logger";
import routes from "@/routes/routes";
import { Subscription } from "@/fetch/mappers/subscriptionMapper";
import { useForm } from "@/utils/form";
import { useNavigateToReceipt } from "@/pages/ReceiptPage/components/navigateToReceipt";
import { ReceiptType } from "@/pages/ReceiptPage/receiptTypes";
import {
  ComplaintCode,
  RegisterComplaintStatus,
  registerComplaint,
} from "@/fetch/subscription/complaint";
import { BrickButton } from "@/components/BrickButton/brickButton";

type Props = {
  subscription: Subscription;
  complaintOptions: ComplaintCode[];
};

// validator does nothing, but cannot be inlined because a new function would be
// these variables needs to be outside Component,
// else they will be created on every render -> forever render-loop
const validator = () => ({});
const formMessages = {};

const Complaint: ComponentType<Props> = ({
  subscription,
  complaintOptions,
}) => {
  const t = useTranslate(messages);
  const navigate = useNavigate();
  const navigateToReceipt = useNavigateToReceipt();
  const [showValidationError, setShowValidationError] = useState<boolean>();

  const useSubmit = (subscriptionId: string) => {
    return useCallback(
      async ({ complaint, date }: ComplaintForm) => {
        setSubmitting(true);
        const response = await registerComplaint({
          subscriptionId,
          formData: {
            code: complaint.code,
            date,
          },
        });

        if (response.status === RegisterComplaintStatus.Success) {
          return navigateToReceipt({
            type: ReceiptType.DeliveryComplaint,
            reason: complaint.name,
            date: date,
          });
        }

        if (response.status === RegisterComplaintStatus.ValidationError) {
          setShowValidationError(true);
          setSubmitting(false);
          return;
        }

        log.error("Register complaint failed", response.error);
        return navigate(routes.error.path());
      },
      [subscriptionId],
    );
  };

  const submit = useSubmit(subscription.id);

  const initial: ComplaintForm = useMemo(
    () => ({
      complaint: {
        code: complaintOptions[0].code,
        name: complaintOptions[0].name,
      },
      date: dayjs().format("YYYY-MM-DD"),
    }),
    [complaintOptions],
  );

  const {
    errors,
    submitForm,
    values,
    setValue,
    isSubmitting,
    setSubmitting,
    onBlur,
  } = useForm(initial, formMessages, validator, submit);

  const onDatePickerChanged = useCallback(
    (selectedDate: string) => {
      setShowValidationError(false);
      setValue("date", selectedDate);
    },
    [setValue],
  );

  const onSelectCodeChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const complaint = complaintOptions.find(
        (f) => f.code === event.target.value,
      );
      setShowValidationError(false);
      setValue("complaint", complaint ?? initial.complaint);
    },
    [setValue, complaintOptions, initial.complaint],
  );

  return (
    <Sheet>
      {showValidationError && (
        <ValidationError>
          <FailureBox title={t("validationErrorTitle")}>
            <p>
              {t("validationErrorNoDelivery", {
                date: dayjs(values.date).format("DD-MMM-YYYY"),
              })}
            </p>
            <p>{t("validationErrorNextStep")}</p>
          </FailureBox>
        </ValidationError>
      )}
      <InputError error={errors.global} />
      <InputField description={t("reasonTitle")}>
        <Select
          name="code"
          onChange={onSelectCodeChange}
          error={!!errors.complaint}
          onBlur={onBlur}
        >
          {complaintOptions.map((complaint) => (
            <Option key={complaint.code} value={complaint.code}>
              {complaint.name}
            </Option>
          ))}
        </Select>
      </InputField>
      <InputField description={t("when")}>
        <DatePicker
          maxDate={dayjs().format("YYYY-MM-DD")}
          minDate={dayjs().subtract(7, "day").format("YYYY-MM-DD")}
          selectedDate={values.date}
          onChange={onDatePickerChanged}
          $error={!!errors.date}
          onBlur={onBlur}
        />
      </InputField>
      <SubmitContainer>
        <BrickButton
          as="button"
          label={t("submit")}
          version="primary"
          disabled={isSubmitting}
          width="normal"
          onClick={submitForm}
        />
      </SubmitContainer>
    </Sheet>
  );
};

export type ComplaintForm = {
  complaint: ComplaintCode;
  date: string;
};

const SubmitContainer = styled.div`
  display: flex;
  margin-top: 20px;
`;

const ValidationError = styled.div`
  margin-bottom: 20px;
  p {
    margin: 10px 0 0 0;
  }
`;

const messages = {
  reasonTitle: {
    nb: "Hva har skjedd?",
    nn: "Kva har skjedd?",
  },
  when: {
    nb: "Når skjedde dette?",
    nn: "Når skjedde dette?",
  },
  submit: {
    nb: "Send inn",
    nn: "Send inn",
  },
  validationErrorTitle: {
    nb: "Kunne ikke levere klage",
    nn: "Kunne ikkje levere klage",
  },
  validationErrorNoDelivery: {
    nb: "Avisen leveres ikke på valgt dato: {{date}}.",
    nn: "Avisa vert ikkje levert på valgt dato: {{date}}.",
  },
  validationErrorNextStep: {
    nb: "Sjekk at du har valgt riktig dato og prøv på nytt, eller kontakt kundeservice.",
    nn: "Sjekk at du har valgt riktig dato og prøv på nytt, eller kontakt kundeservice.",
  },
};

export default Complaint;
