import React, { PropsWithChildren } from "react";
import { Link } from "react-router-dom";

import { Sheet } from "@/components/Sheet";
import { useTranslate } from "@/i18n";
import routes from "@/routes/routes";
import { BrickButton } from "@/components/BrickButton/brickButton";

const Receipt: React.FC<PropsWithChildren> = ({ children }) => {
  const t = useTranslate(messages);
  return (
    <>
      <Sheet>
        {children}
        <Link to={routes.myPage.path()}>
          <BrickButton
            as="span"
            label={t("overviewLink")}
            version="primary"
            width="normal"
          />
        </Link>
      </Sheet>
    </>
  );
};

const messages = {
  overviewLink: {
    nb: "Min side",
    nn: "Mi side",
  },
};

export default Receipt;
