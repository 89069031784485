import React, { useContext, PropsWithChildren, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";

import DeliveryComplaintReceipt from "./components/deliveryComplaintReceipt";
import SavePermanentMoveReceipt from "./components/savePermanentMoveReceipt";
import TemporaryAddressChangeReceipt from "./components/temporaryAddressChangeReceipt";
import TemporaryStopReceipt from "./components/temporaryStopReceipt";
import SubscriptionCancelledReceipt from "./components/subscriptionCancelledReceipt";
import ResubscribeReceipt from "./components/resubscribeReceipt";
import { ReceiptData, ReceiptType } from "./receiptTypes";
import SubscriptionCancelledReceiptSimple from "./components/subscriptionCancelledReceiptSimple";

import AppContext from "@/contexts/appContext";
import { pageTitle } from "@/utils/page";
import routes from "@/routes/routes";
import { log } from "@/logging/logger";
import BreadCrumbs from "@/components/Breadcrumbs";
import { useSubscriptionFetcher } from "@/fetch/subscription/useSubscriptionFetcher";
import Adplogger2Wrapper from "@/components/Adplogger2/Adplogger2Wrapper";

const ReceiptPage: React.FC = () => {
  const context = useContext(AppContext);
  const { locale, domain, name } = context.site;
  const { forceRefetch } = useSubscriptionFetcher(locale, domain);
  const receiptData = useLocation().state as ReceiptData;

  useEffect(() => {
    forceRefetch();
  }, []);

  const title = routes.receipt.title[locale];
  pageTitle.set(pageTitle.create(name.short, title));

  if (!receiptData) {
    log.error("On receipt page without receiptData");
    return <Navigate to={routes.error.path()} replace={true} />;
  }

  type Props = PropsWithChildren & { type: ReceiptType };

  const Receipt: React.FC<Props> = ({ children, type }) => (
    <>
      <BreadCrumbs>{title}</BreadCrumbs>
      <Adplogger2Wrapper adpType="View" name={`Kvittering: ${type}`}>
        {children}
      </Adplogger2Wrapper>
    </>
  );

  const selectReceipt = () => {
    switch (receiptData.type) {
      case ReceiptType.PermanentMove:
        return <SavePermanentMoveReceipt {...receiptData} />;
      case ReceiptType.TemporaryAddressChange:
        return <TemporaryAddressChangeReceipt {...receiptData} />;
      case ReceiptType.DeliveryComplaint:
        return <DeliveryComplaintReceipt {...receiptData} />;
      case ReceiptType.TemporaryStop:
        return <TemporaryStopReceipt {...receiptData} />;
      case ReceiptType.SubscriptionCancelled:
        return <SubscriptionCancelledReceipt {...receiptData} />;
      case ReceiptType.SubscriptionCancelledSimple:
        return <SubscriptionCancelledReceiptSimple {...receiptData} />;
      case ReceiptType.Resubscribe:
        return <ResubscribeReceipt />;
      default:
        log.error(
          `Fant ingen kvitteringstype for ${JSON.stringify(receiptData)}`,
        );
        return <Navigate to={routes.error.path()} replace={true} />;
    }
  };

  return <Receipt type={receiptData.type}>{selectReceipt()}</Receipt>;
};

export default ReceiptPage;
