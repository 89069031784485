import React from "react";
import { styled } from "styled-components";

import { Sheet } from "@/components/Sheet";
import { Subscription } from "@/fetch/mappers/subscriptionMapper";
import { useTranslate } from "@/i18n";
import { StartAutoInvoiceForm } from "@/pages/ChangePaymentPage/components/paymentMethods/startAutoInvoiceForm";
import colors from "@/theme/colors";
import { BrickButton } from "@/components/BrickButton/brickButton";

type Props = {
  subscription: Subscription;
};

const AutoInvoicePromo: React.FC<Props> = ({ subscription }) => {
  const t = useTranslate(messages);

  return (
    <Sheet $borderTopColor={colors.amediaGreen}>
      <Recommended>{t("recommended")}</Recommended>
      <Header>{t("title")}</Header>
      <p>{t("body")}</p>

      <StartAutoInvoiceForm
        subscriptionId={subscription.id}
        kidNumber={subscription.kidNumber}
        submitButton={() => (
          <BrickButton
            as="button"
            label={t("button")}
            version="primary"
            width="normal"
            type="submit"
          />
        )}
      />
    </Sheet>
  );
};

const Recommended = styled.p`
  margin: 0px;
`;

const Header = styled.h2`
  margin-top: 0px;
`;

export default AutoInvoicePromo;

const messages = {
  recommended: {
    nb: "Anbefalt!",
    nn: "Anbefalt!",
  },
  body: {
    nb: "Betal i tide med automatisk trekk fra banken. Unngå purringer og gebyrer. Det tar bare et par minutter å bytte. Hvis du allerede har opprettet AvtaleGiro kan du se bort ifra dette.",
    nn: "Betal i tide med automatisk trekk frå banken. Unngå purringar og gebyrer. Det tek berre eit par minuttar å byte. Dersom du allereie har opprettet AvtaleGiro kan du se bort ifra dette.",
  },
  title: {
    nb: "AvtaleGiro - spar 100 kroner på neste faktura",
    nn: "AvtaleGiro - spar 100 kroner på neste faktura",
  },
  button: {
    nb: "Bytt til Avtalegiro",
    nn: "Byt til Avtalegiro",
  },
};
