import { SubscriptionInfoCard } from "./subscriptionInfoCard";
import {
  ExpandedSubscriptionContainer,
  ExpandedContent,
} from "./expandedSubscriptionContainer";

import { useTranslate } from "@/i18n";
import { useMediaQuery } from "@/hooks/useMediaQuery";

interface PodcastInfoProps {
  publicationDomain: string;
}

export const PodcastInfo = ({ publicationDomain }: PodcastInfoProps) => {
  const t = useTranslate(podcastText);
  const isMobileDevice = useMediaQuery("(max-width: 680px)");

  const untoldButtons = [
    {
      id: "ios",
      label: isMobileDevice ? t("ios") : t("downloadIos"),
      href: "https://apps.apple.com/no/app/untold-podcasts/id1611737079",
    },
    {
      id: "android",
      label: isMobileDevice ? t("android") : t("downloadAndroid"),
      href: "https://play.google.com/store/apps/details?id=play.podcasts&gl=NO&pli=1",
    },
  ];

  const podcastContent: ExpandedContent[] = [
    {
      id: "untold-01",
      title: t("podcastTitlePointOne"),
      content: t("podcastDescriptionPointOne"),
      subContent: t("podcastSubDescriptionPointOne"),
    },
    {
      id: "untold-02",
      title: t("podcastTitlePointTwo"),
      content: t("podcastDescriptionPointTwo"),
      subContent: t("podcastSubDescriptionPointTwo"),
      previewImage: true,
    },
    {
      id: "untold-03",
      title: t("podcastTitlePointThree"),
      content: t("podcastDescriptionPointThree", { publicationDomain }),
    },
  ];
  return (
    <SubscriptionInfoCard
      id="podcast"
      type="podcast"
      title={t("title")}
      description={[
        t("podcastDescription", { publicationDomain }),
        t("podcastSubDescription", { publicationDomain }),
      ]}
      buttons={untoldButtons}
      helperText={t("helperText")}
      expandedContent={
        <ExpandedSubscriptionContainer
          contentList={podcastContent}
          type="podcast"
        />
      }
    />
  );
};

export default PodcastInfo;

const podcastText = {
  title: {
    nb: "Du har tilgang til podkastappen Untold",
    nn: "Du har tilgang til podkastappen Untold",
  },
  podcastDescription: {
    nb: "Som abonnent på {{publicationDomain}} har du inkludert tilgang til alt innhold i Untold. Untold har Norges største utvalg av egenproduserte podkaster, og du finner noe for enhver smak. Podkastene varierer fra true crime til livsstil, økonomi til fotball.  ",
    nn: "Som abonnent på {{publicationDomain}} har du inkludert tilgang til alt innhald i Untold. Untold har Noregs største utvalg av eigenproduserte podkastar, og du finn noko for einkvar smak. Podkastane varierer frå true crime til livsstil, økonomi til fotball.",
  },
  podcastSubDescription: {
    nb: "Du logger deg inn i Untold med den samme aID-en som som du bruker for å logge inn på {{ publicationDomain }}.",
    nn: "Du loggar deg inn i Untold med den same aID-en som du brukar for å logge inn på {{ publicationDomain }}",
  },
  podcastTitlePointOne: {
    nb: "Hva kan jeg høre på?",
    nn: "Kva kan eg høyre på? ",
  },
  podcastDescriptionPointOne: {
    nb: "Untold har en stor samling originale podkaster, som Truecrimepodden og Historiepodden. Du har tilgang til alt innhold, inkludert det som er eksklusivt. Alt er uten reklame.",
    nn: "Untold har ein stor samling originale podkastar, som Truecrimepodden og Historiepodden. Du har tilgang til alt innhald, inkludert det som er eksklusivt. Alt er utan reklame.",
  },
  podcastSubDescriptionPointOne: {
    nb: "For deg som har et ekstra hjerte for engelsk fotball kan du nå også høre Premier League-kampene direkte i appen. Perfekt om du er på farta mens favorittlaget spiller.",
    nn: "For deg som har eit ekstra hjarte for engelsk fotball kan du no også høyre Premier League-kampane direkte i appen. Perfekt om du er på farta mens favorittlaget spelar.",
  },
  podcastTitlePointTwo: {
    nb: "Våre mest populære podkaster",
    nn: "Våre mest populære podkastar",
  },
  podcastDescriptionPointTwo: {
    nb: "Lytt til Truecrimepodden, Krisemøter, Pengesnakk, Henrettelsespodden, Søvnløs, Skrekkpodden og Historiepodden. ",
    nn: "Lytt til Truecrimepodden, Krisemøter, Pengesnakk, Henrettelsespodden, Søvnløs, Skrekkpodden og Historiepodden. ",
  },
  podcastSubDescriptionPointTwo: {
    nb: "I tillegg kan du høre på Wolfgang Wee Uncut, Hjernesterk med Ole Petter Hjelle og Mads Kaggestad, Debrief med Dag Sørås, Fantasyrådet og Spionpodden.",
    nn: "I tillegg kan du høyre på Wolfgang Wee Uncut, Hjernesterk med Ole Petter Hjelle og Mads Kaggestad, Debrief med Dag Sørås, Fantasyrådet og Spionpodden. ",
  },
  podcastTitlePointThree: {
    nb: "Hvordan kommer jeg inn i appen?",
    nn: "Korleis kjem eg inn i appen?",
  },
  podcastDescriptionPointThree: {
    nb: "For å bruke og lytte til innholdet i Untold må du laste ned appen på iOS eller Android, og velge aID som innloggingsmetode. aID er den samme innloggingsmetoden som du bruker på {{publicationDomain}}.",
    nn: "For å bruke og lytte til innhaldet i Untold må du laste ned appen på iOS eller Android, og velje aID som innloggingsmetode. aID er den same innloggingsmetoden som du brukar på {{publicationDomain}}. ",
  },
  helperText: {
    nb: "Last ned appen for iOS eller Android",
    nn: "Last ned appen for iOS eller Android",
  },
  ios: {
    nb: "iOS",
    nn: "iOS",
  },
  android: {
    nb: "Android",
    nn: "Android",
  },
  downloadIos: {
    nb: "Last ned for iOS",
    nn: "Last ned for iOS",
  },
  downloadAndroid: {
    nb: "Last ned for Android",
    nn: "Last ned for Android",
  },
};
