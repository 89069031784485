import { styled } from "styled-components";

export const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 25px;
  gap: 20px;

  > * {
    min-width: 180px;
  }
`;
