import React from "react";
import { styled } from "styled-components";
import type { Invoice as InvoiceType } from "@amedia/node-clients-abo";

import TranslatedText from "@/components/TranslatedText";
import { Translation, useTranslate } from "@/i18n";
import { dateCompare, dateFormat } from "@/utils/date";
import { InnerSheet } from "@/components/Sheet";

type OrderLine = InvoiceType["orderLines"][0];

type Props = {
  invoice: InvoiceType;
};

const Invoice: React.FC<Props> = ({ invoice }) => {
  const t = useTranslate(messages);

  const orderLines = invoice.orderLines.filter((o) => o.startDate && o.endDate);

  const subscriptionType =
    orderLines.length > 0
      ? orderLines
          .map((o) => o.description)
          .reduce((prev: Translation, current: Translation) => ({
            nb: `${prev.nb}, ${current.nb}`,
            nn: `${prev.nn}, ${current.nn}`,
          }))
      : null;

  const genericType = subscriptionType
    ? null
    : invoice.orderLines[0]?.description;

  const dateRange = getDateRange(invoice.orderLines);
  return (
    <InnerSheet data-testid="invoice">
      <DueDate>
        <strong>{t("dueDate")}:</strong> {dateFormat(invoice.dueDate)}
      </DueDate>
      {dateRange && (
        <div>
          {t("period")}: {dateRange}
        </div>
      )}
      {subscriptionType && (
        <div>
          {t("subscriptionType")}: <TranslatedText text={subscriptionType} />
        </div>
      )}
      {genericType && (
        <div>
          {t("genericType")}: <TranslatedText text={genericType} />
        </div>
      )}
      <div>
        {t("amount")}: {invoice.totalAmount.totalAmount} kr
      </div>
    </InnerSheet>
  );
};

const getDateRange = (orderLines: OrderLine[]): string => {
  const from = orderLines
    .filter((o) => !!o.startDate)
    .map((o) => o.startDate as string)
    .sort(dateCompare)
    .shift();
  const to = orderLines
    .filter((o) => !!o.endDate)
    .map((o) => o.endDate as string)
    .sort(dateCompare)
    .pop();
  if (!from || !to) {
    return "";
  }
  return `${dateFormat(from)} - ${dateFormat(to)}`;
};

const DueDate = styled.span`
  display: block;
  font-size: 18px;
  margin-bottom: 15px;
`;

export default Invoice;

const messages = {
  dueDate: {
    nb: "Forfallsdato",
    nn: "Forfallsdato",
  },
  period: {
    nb: "Abonnementsperiode",
    nn: "Abonnementsperiode",
  },
  subscriptionType: {
    nb: "Abonnementstype",
    nn: "Abonnementstype",
  },
  genericType: {
    nb: "Beskrivelse",
    nn: "Beskriving",
  },
  paymentMethod: {
    nb: "Betalingsmetode",
    nn: "Betalingsmetode",
  },
  amount: {
    nb: "Beløp",
    n: "Beløp",
  },
  details: {
    nb: "Detaljer",
    nn: "Detaljer",
  },
};
