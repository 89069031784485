import React, { useContext } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";

import { ButtonContainer } from "@/components/ButtonContainer";
import Heading from "@/components/Heading";
import InputError from "@/components/InputError";
import PageHeader from "@/components/PageHeader";
import { Sheet } from "@/components/Sheet";
import { SubscriptionOutlet } from "@/components/SubscriptionRoute/subscriptionRoute";
import AppContext from "@/contexts/appContext";
import { useTranslate } from "@/i18n";
import { log } from "@/logging/logger";
import { useNavigateToReceipt } from "@/pages/ReceiptPage/components/navigateToReceipt";
import routes from "@/routes/routes";
import { useForm } from "@/utils/form";
import { ReceiptType } from "@/pages/ReceiptPage/receiptTypes";
import { cancelSubscription } from "@/fetch/subscription/cancelSubscription";
import { BrickButton } from "@/components/BrickButton/brickButton";

export type ConfirmCancelProps = {
  changeDate: string;
  reasonCode: string;
};

const CancelConfirmation: React.FC<ConfirmCancelProps> = (props) => {
  const { changeDate, reasonCode } = props;
  const t = useTranslate(messages);
  const { subscription } = useOutletContext<SubscriptionOutlet>();
  const context = useContext(AppContext);
  const navigateToReceipt = useNavigateToReceipt();
  const initial = { reasonCode, changeDate };

  const navigate = useNavigate();

  const submit = async () => {
    const { data, error } = await cancelSubscription({
      formData: { cancelReasonCode: reasonCode, changeDate },
      subscriptionId: subscription.id,
      publicationDomain: context.site.domain,
    });

    if (data) {
      return navigateToReceipt({
        type: ReceiptType.SubscriptionCancelled,
        pendingCancellationDate: data.pendingCancellationDate,
      });
    }
    setSubmitting(false);
    if (!reasonCode || !changeDate) {
      log.info(
        `User on cancel page with invalid or missing values. reasonCode: ${reasonCode}, changeDate: ${changeDate}`,
        error,
      );
    } else {
      log.error("Cancel confirmation failed", error?.message);
    }
    return navigate(routes.error.path());
  };

  const { errors, submitForm, isSubmitting, setSubmitting } = useForm(
    initial,
    formMessages,
    validator,
    submit,
  );

  return (
    <>
      <PageHeader>{t("title")}</PageHeader>
      <InputError error={errors.global} />
      <Sheet $primary>
        <Heading>{t("heading")}</Heading>
        <p>{t("campaignRiderInfo")}</p>
        <p>{t("confirmationQuestion")}</p>
        <ButtonContainer>
          <BrickButton
            as="button"
            label={t("submit")}
            version="primary"
            disabled={isSubmitting}
            onClick={submitForm}
          />
          <Link to={routes.myPage.path()}>
            <BrickButton as="span" label={t("cancel")} version="outlined" />
          </Link>
        </ButtonContainer>
      </Sheet>
    </>
  );
};

export default CancelConfirmation;

const validator = () => ({});
const formMessages = {};

const messages = {
  title: {
    nb: "Si opp abonnementet",
    nn: "Sei opp abonnementet",
  },
  campaignRiderInfo: {
    nb: "Husk at du ikke vil kunne kjøpe et nytt abonnement til introduksjonspris på denne avisen, eller på noen av de andre avisene i Amedia (inkludert våre samarbeidsaviser).",
    nn: "Husk at du ikkje vil kunne kjøpe eit nytt abonnement til introduksjonspris på denne avisa, eller på nokon av dei andre avisene i Amedia (inkludert våre samarbeidsaviser)",
  },
  confirmationQuestion: {
    nb: "Ønsker du allikevel å si opp?",
    nn: "Ønskjer du likevel å seie opp no?",
  },
  submit: {
    nb: "Ja, si opp nå",
    nn: "Ja, sei opp no",
  },
  cancel: {
    nb: "Nei, behold abonnementet",
    nn: "Nei, behald abonnementet",
  },
  heading: {
    nb: "Informasjon om pris",
    nn: "Informasjon om pris",
  },
};
