import { FunctionComponent } from "react";

import { useTranslate } from "@/i18n";
import { ButtonContainer } from "@/components/ButtonContainer";
import { InnerSheet } from "@/components/Sheet";
import { startChangeCreditCard } from "@/fetch/payment/creditCard";
import { BrickButton } from "@/components/BrickButton/brickButton";

type Props = {
  subscriptionId: string;
};

const CreditCard: FunctionComponent<Props> = ({ subscriptionId }) => {
  const t = useTranslate(messages);

  return (
    <InnerSheet data-testid="change-to-credit-card">
      <div>
        <strong>{t("title")}</strong>
      </div>
      <p>{t("description")}</p>
      <ButtonContainer>
        <BrickButton
          as="button"
          label={t("cta")}
          version="primary"
          width="normal"
          onClick={() =>
            startChangeCreditCard({
              subscriptionId,
            })
          }
        />
      </ButtonContainer>
    </InnerSheet>
  );
};

export default CreditCard;

const messages = {
  title: {
    nb: "Betalingskort",
    nn: "Betalingskort",
  },
  description: {
    nb: "Betal abonnementet ditt med betalingskort.",
    nn: "Betal abonnementet ditt med betalingskort.",
  },
  cta: {
    nb: "Bytt til betalingskort",
    nn: "Byt til betalingskort",
  },
};
