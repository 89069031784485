import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  :root {
    /* We only use these for specific parts of brandheader */
    --title-font: "FaktorTittel"; /* Used for Nettavisen logo in brandheader. Only Nettavisen uses this variable? */
    /* Below are copied from */
    /* https://github.com/amedia/maelstrom/blob/master/maelstrom-templates/lib/css-config/css-default-vars.js */
    --swatchMono-black: #292827; /* Used for hamburger menu colors in brandheader */
    --openSans: "Open Sans", "Helvetica Neue", Helvetica, "Segoe ui", Arial,
      sans-serif; /* Used by non-nettavisen footer */
  }

  body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background: var(--page-color, #f5f5f5);
    margin: 0;
    padding: 0;
    font-size: 16px;
  }

  /*  Background colors is not currently available in brick. This is how it is done in maelstrom */
  .themeAlfa,
  .themeNettavisen {
    --page-color: #f5f5f5;
  }
  .themeBravo {
    --page-color: #e5e5e5;
  }
  .themeCharlie {
    --page-color: #eee7e1;
  }

  body #vorwerk-footer-wrapper {
    display: none;
    margin-top: auto;
  }

  #app :is(a:hover, a:active):not(.link-button) {
    text-decoration: none;
  }

  #app {
    max-width: 1000px;
    width: 100%;
    margin: 40px auto 100px auto;
    min-height: 500px;
    box-sizing: border-box;
  }

  #app *,
  #app *:before,
  #app *:after {
    /* By using inheritance it is easier to override the box sizing if necessary */
    box-sizing: inherit;
  }

  .subscriptionCard a:not(.link-button),
  .subscriptionCard a:visited:not(.link-button) {
    color: inherit;
  }

  #skip-to-main-content {
    position: absolute;
    z-index: 999999;
    opacity: 0;
    padding: 8px;
    background: #ff0;
    font-family: var(--brick-fonts-baseBodyM);
    font-weight: var(--brick-fontWeights-baseBodyM);
    color: var(--brick-colors-baseFg);
    cursor: pointer;
    pointer-events: none;
    font-size: 1.3rem;
  }

  #skip-to-main-content:focus {
    opacity: 1;
    pointer-events: auto;
  }

  .userLink {
    color: inherit;
  }
`;
