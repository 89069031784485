export const useCreateSkipToContentButton = (locale: string) => {
  if (document.querySelector("#skip-to-main-content") !== null) {
    return;
  }

  const button = document.createElement("button");
  button.id = "skip-to-main-content";
  button.textContent =
    locale === "nn"
      ? "Gå til sidas hovudinnhald"
      : "Gå til sidens hovedinnhold";
  button.onclick = () => {
    window.location.replace("#app");
  };

  const body = document.querySelector("body");
  if (body) {
    body.prepend(button);
  }
};
