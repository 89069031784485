import pinoLogger, { LogEvent } from "pino";

import eik from "../../eik.json";
import { isHttpError, isNetworkError } from "../fetch/errors";

import { getCircularReplacer } from "./utils";

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};

const fetchFn = (logEvent: LogEvent) =>
  fetch("/api/vorwerk/log", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(logEvent),
  });

const shouldIgnoreLogEvent = (logEvent: LogEvent) =>
  [/^avbrutt/, /^cancelled/].some((regex) =>
    regex.test(logEvent.messages[1] || ""),
  );

const logger = pinoLogger({
  browser: {
    // Need noop to avoid printing to browser console
    write: noop,
    transmit: {
      send: (_level, logEvent: LogEvent) => {
        if (shouldIgnoreLogEvent(logEvent)) {
          return;
        }
        logEvent.bindings = [
          ...logEvent.bindings,
          { "vorwerk.page": location.href },
        ];
        fetchFn(logEvent).catch(() => ({}));
      },
    },
  },
});

const clientLogger = (error?: Error | string | unknown) =>
  logger.child({
    "vorwerk.client_version": eik.version,
    "vorwerk.initial_client_load": new Date().toISOString(),
    "vorwerk.user_agent": navigator.userAgent,
    "vorwerk.initial_page": location.href,
    ...(!!error && {
      "vorwerk.error":
        error instanceof Error
          ? JSON.stringify(error, Object.getOwnPropertyNames(error))
          : JSON.stringify(error, getCircularReplacer()),
    }),
  });

const filteredLogger = (
  e: Error | unknown,
  doLog: (extraInfo?: string) => void,
) => {
  if (isNetworkError(e)) {
    return;
  }

  if (isHttpError(e)) {
    if (e.status >= 400 && e.status < 500) {
      clientLogger(e).warn(
        `Fetch failed with client error. Status: ${e.status}`,
      );
      return;
    } else if (e.status >= 500) {
      doLog(`Status: ${e.status}`);
      return;
    }
  }
  doLog();
};

export const log = {
  error: (message: string, error?: Error | unknown) =>
    filteredLogger(error, (extraInfo = "") =>
      clientLogger(error).error(`${message} ${extraInfo}`),
    ),
  warn: (message: string, error?: Error | unknown) =>
    filteredLogger(error, (extraInfo = "") =>
      clientLogger(error).warn(`${message} ${extraInfo}`),
    ),
  info: (message: string, error?: Error | unknown) =>
    filteredLogger(error, (extraInfo = "") =>
      clientLogger(error).info(`${message} ${extraInfo}`),
    ),
};
