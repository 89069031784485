import { log } from "@/logging/logger";

const hex2num = (str: string) =>
  Number("0x" + str.split(/[^0-9a-fA-F]+/).join(""));

export enum TestGroup {
  A = "A",
  B = "B",
}

export const getTestGroup = (uuid: string) =>
  hex2num(uuid.slice(-2)) % 64 > 31 ? TestGroup.B : TestGroup.A;

export const getTestGroupFromLastTwoCharactersInUuid = (uuid: string) => {
  if (uuid.length < 16) {
    log.error(`uuid is missing or malformed: ${uuid}`);
    return TestGroup.B;
  }
  const uuidLastTwo = uuid.substring(uuid.length - 2);
  const number = parseInt(uuidLastTwo, 16);

  return number % 2 === 0 ? TestGroup.A : TestGroup.B;
};
